@use "style.scss" as *;
@use "chat.scss" as *;
@use "call-center.scss" as *;
@use "theme.scss" as *;
@use "custom-animations.scss" as *;
@use "tailwindcss";

@layer utilities {

  .scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }

  .scrollbar-hidden {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
}

@import "rc-time-picker/assets/index.css";
@import "react-treeview/react-treeview.css";
@import "react-day-picker/style.css";
@import "react-toggle/style.css";
@import "react-querybuilder/dist/query-builder.css";